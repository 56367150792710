import logo from './logo.svg';
import './App.css';
import { MAndA, CapitalRaise } from './transactions';

import './styles.css';

function App() {
  return (
    <div className="global">
      <Header />
      <TopSection />
      <AboutUs />
      <SelectedTransactions type={'M&A transactions'} transactions={MAndA}/>
      <SelectedTransactions type={'capital raisings'} transactions={CapitalRaise}/>
      <FooterDiv />
    </div>
  );
}

const Header = () => (
  <header>
    <div className="container">
       <div className="navbar navbar_ clearfix">
          <div className="navbar-inner">      
                <div className="clearfix">
                      <h1 className="brand"><a href="index.html"><img src="img/IntrinsicLogo.jpg" alt="" style={{ width: '355px', height: '64px' }} /></a></h1>
                      <div className="nav-collapse nav-collapse_ collapse">
                        <ul className="nav sf-menu clearfix sf-js-enabled">
                          <li className="active" id="top"><a href="/#top">Home</a></li> 
                            <li><a href="#about">About us</a></li> 
                            <li><a href="#transactions">M&A Transactions</a></li> 
                            <li><a href="#capitalraises">Capital Raisings</a></li>
                          </ul>
                      </div>
                </div>
           </div>  
       </div>
    </div>
</header>
);

const TopSection = () => (
  <section>
      <div className="container border1">
          <div className="row">
              <article className="span12 slider">
                  <div className="ip_header">
                      <div><img src="img/waterweb.jpg" className="main-image" /></div>
                      <div className="ip-caption">
                          <p className="title1">Independence. Integrity. Impact.</p>
                          <p className="title2">We provide independent advice to deliver creative and bespoke solutions to best serve the interests of our clients.</p>
                       </div>
                  </div>
              </article>
          </div>
      </div>
  </section>
);

const AboutUs = () => (
  <div class="container padBot">
      <div class="row">
          <section class="span12 border1">
              <div class="row">
                  <article class="span12">
                      <a id="about"></a>
                      <h2>About us</h2>
                      <p>Intrinsic Partners was established in 2015 as an Independent Financial Advisory firm. We form long-term partnerships with our clients and provide financial and strategic advice across the business and capital cycle.</p><br />
                      <p>Our independence, combined with strong networks in both the Australian and International financial markets, allows us to deliver creative and bespoke solutions to best serve the interests of our clients.</p><br />
                      <p>Our team have significant experience across private and public markets and have worked closely with private family offices, private equity firms, privately held companies, domestic public companies and global public companies.</p>
                  </article>
              </div>
          </section>
       </div>
   </div>
);

const SelectedTransactions = ({type, transactions}) => (
  <div className={`container${type === 'M&A transactions' ? ' padBot' : ''}`}>
    <div class="row">
      <section class="span12 border1">
        <div class="row">
          <article class="span12">
            <a id={type === 'M&A transactions' ? 'transactions' : 'capitalraises'}></a>
            <h2>
              Selected
              &nbsp;
              {type}
            </h2>
            <div class="row d-flex flex-wrap no-before">
              {transactions.map((transaction) => {
                return (
                  <div class="col-sm-12 col-md-6 col-lg-3 mb-2">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex flex-wrap justify-content-around" style= {{ height: '50px' }}>
                          <div style={{ maxWidth: '45%' }}>
                              <img src={transaction.imgOne} class="mx-auto mw-100 p-1" style={{ maxHeight: '50px' }} />
                          </div>
                          {transaction.imgTwo && <div style={{ maxWidth: '45%' }}>
                              <img src={transaction.imgTwo} class="mx-auto mw-100 p-1" style={{ maxHeight: '50px' }} />
                          </div>}
                        </div>
                      </div>
                      <div class="card-header d-flex align-items-start flex-column text-center transaction-card">
                          <p class="mx-auto mb-2"><strong>{transaction.client}</strong></p>
                          <p class="mx-auto">{transaction.role}</p>
                          <span class="mt-auto mx-auto"><strong>{transaction.time}</strong></span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </article>
        </div>
      </section>
    </div>
  </div>
);

const FooterDiv = () => (
  <footer>
      <div class="container">
          <div class="row" style={{ color: 'white', display: 'block' }}>
              <p><strong>Phone:</strong> +61 3 9999 1470</p>
              <p><strong>Email:</strong> <a href="mailto:info@intrinsicpartners.com.au">info@intrinsicpartners.com.au</a></p>
              <p><strong>Address:</strong> Level 1, 7 Howard St, Richmond VIC 3121</p>
              <p style={{ borderTop: '1px solid white', paddingTop: '5px', marginTop: '5px' }}>Intrinsic Partners Pty Ltd ABN 51 604 731 857 AFSL 475135 © 2020 | <a href="privacy.html">Privacy Policy</a> | Terms of use</p>
          </div>
      </div>   
  </footer>
);

export default App;
